import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { initGoogleAnalytics, trackPageView } from './analytics';
import SplashScreen from './components/SplashScreen';
import Header from './components/Header-HomeScreen';
import HeaderContactScreen from './components/Header-ContactScreen';
import Footer from './components/FooterScreen';
import Popup from './components/Popup';
import { ThemeContext, ThemeProvider } from './components/ThemeContext';
import './App.css';
import { Helmet } from 'react-helmet';

// Lazy loading pour les composants
const FaqsScreen = lazy(() => import('./screen/FAQSScreen'));
const TelechargerScreen = lazy(() => import('./screen/TelechargerScreen'));
const MyBellisScreen = lazy(() => import('./screen/MybellisScreen'));
const ContactScreen = lazy(() => import('./screen/ContactScreen'));
const HomeComponents = {
  Application: lazy(() => import('./screen/HomeScreen/Application')),
  ContentSection: lazy(() => import('./screen/HomeScreen/ContentSection')),
  EnvironmentalImpact: lazy(() => import('./screen/HomeScreen/EnvironmentalImpact')),
  Embellis: lazy(() => import('./screen/HomeScreen/Embellis')),
  Services: lazy(() => import('./screen/HomeScreen/Services')),
  MybellisC: lazy(() => import('./screen/HomeScreen/Mybellis')),
  CommentCaMarche: lazy(() => import('./screen/HomeScreen/CommentCaMarche'))
};

// Analytics
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search + location.hash;
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path,
        page_location: window.location.href,
        page_title: document.title
      });
    }
    trackPageView(page_path);
  }, [location]);

  return null;
};

// ScrollToTop
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

// Pages
const Home = () => {
  useEffect(() => {
    document.title = "Belli's - Découvrez des produits bio et locaux, écoresponsables près de chez vous";
  }, []);

  return (
    <div className="App">
      <Header />
      <Popup />
      <Suspense>
        <HomeComponents.Application />
        <HomeComponents.ContentSection />
        <HomeComponents.CommentCaMarche />
        <HomeComponents.EnvironmentalImpact />
        <HomeComponents.Embellis />
        <HomeComponents.MybellisC />
        <HomeComponents.Services />
      </Suspense>
      <Footer />
    </div>
  );
};

const MyBellisProgram = () => {
  useEffect(() => {
    document.title = "Bellis - Programme MyBellis";
  }, []);

  return (
    <main className="MyBellisProgram">
      <HeaderContactScreen />
      <Suspense>
        <MyBellisScreen />
      </Suspense>
      <Footer />
    </main>
  );
};

const Contact = () => {
  useEffect(() => {
    document.title = "Bellis - Contact";
  }, []);

  return (
    <main className="ContactScreen">
      <HeaderContactScreen />
      <Suspense>
        <ContactScreen />
      </Suspense>
      <Footer />
    </main>
  );
};

const FAQS = () => {
  useEffect(() => {
    document.title = "Bellis - FAQ";
  }, []);

  return (
    <main className="FAQS">
      <Header />
      <Suspense>
        <FaqsScreen />
      </Suspense>
      <Footer />
    </main>
  );
};

const TelechargerApp = () => {
  useEffect(() => {
    document.title = "Bellis - Télécharger l'application";
  }, []);

  return (
    <main className="TelechargerScreen">
      <Suspense>
        <TelechargerScreen />
      </Suspense>
    </main>
  );
};

// Routes configuration
const routes = [
  { path: "/", element: <Home />, title: "Belli's - Découvrez des produits bio et locaux, écoresponsables près de chez vous"  },
  { path: "/mybellisprogram", element: <MyBellisProgram />, title: "Programme MyBellis" },
  { path: "/contact", element: <Contact />, title: "Contact" },
  { path: "/FAQS", element: <FAQS />, title: "FAQ" },
  { path: "/application-mobile", element: <TelechargerApp />, title: "Télécharger l'application" }
];

// AppRoutes
const AppRoutes = () => (
  <Routes>
    {routes.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </Routes>
);

// App Component
const App = () => {

  const [showSplash, setShowSplash] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    // Initialiser Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-W5BBCV2NSB', {
      page_path: window.location.pathname,
      send_page_view: true
    });

    initGoogleAnalytics();

    // Gestion du splash screen
    const splashShown = sessionStorage.getItem('splashShown');
    if (!splashShown) {
      setShowSplash(true);
      sessionStorage.setItem('splashShown', 'true');
    }

    // Préchargement des composants
    const preloadComponents = () => {
      // Précharger les composants de la page d'accueil
      Object.values(HomeComponents).forEach(component => {
        if (typeof component.preload === 'function') {
          component.preload();
        }
      });

      // Précharger les autres composants principaux
      [FaqsScreen, TelechargerScreen, MyBellisScreen, ContactScreen].forEach(component => {
        if (typeof component.preload === 'function') {
          component.preload();
        }
      });
    };

    const timeoutId = setTimeout(preloadComponents, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (showSplash) {
    return <SplashScreen onComplete={() => setShowSplash(false)} />;
  }

  return (
    <ThemeProvider>
         <Helmet>
        <title>Belli's - Découvrez des produits bio et locaux, écoresponsables près de chez vous</title>
        <meta name="description" content="Retrouvez tous vos producteurs et artisans préférés dans un rayon de 30 km autour de vous sur l'application Belli's" />
      </Helmet>
      <div style={{ background: theme.background, color: theme.text }}>
        <Router>
          <RouteTracker />
          <ScrollToTop />
          <AppRoutes />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;